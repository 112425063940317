import React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Text,
  Box,
  Flex,
  Button,
  Heading,
  Container,
  UnorderedList,
  ListItem,
  Grid,
  GridItem,
  Link as ChLink,
} from '@chakra-ui/react';

import Img from 'gatsby-image';
import shortid from 'shortid';
import SmartQuote from '../assets/smart-quotes.svg';
import { Layout, ScrollAnimation, LinkOne } from '../components/index';

const makeId = () => {
  return shortid.generate();
};

const services = [
  {
    name: 'Health System and hospitals',
    services: [
      'Managed care financial analysis and modeling',
      'Managed care contract assessments',
      'Health plan and provider contract negotiations',
      'Interim management support/heath plan negotiation',
      'Staffing plans and recommendations',
      'Market assessments and analysis',
    ],
  },
  {
    name: 'Medical Groups and IPAs',
    services: [
      'Health Plan Contract Negotiations',
      'Physician Recruitment Efforts',
      'Medical Group and IPA Development',
      'Managed Care Marketing Analysis',
      'Program Development and Service Line Opportunities',
    ],
  },
  {
    name: 'Other services',
    services: [
      'Hospital/Physician Joint Venture Programs and Services (e.g. ASC, MRI, cardiac surgery, rehabilitation and other specialized programs)',
      'Business Plan Development and Financial Projections',
      'Volume and Program Planning',
      'Hospitalist Program Development',
    ],
  },
];

const Hero = ({ data }) => (
  <Box bg="brand.dark" color="white" height="100%" pt={20} pb={10}>
    <Container maxW="container.xl">
      <Flex
        flexWrap="wrap"
        direction={{ sm: 'column', lg: 'row' }}
        justifyContent={{ sm: 'center', lg: 'space-between' }}
        alignItems={{ sm: 'center', lg: 'flex-start' }}
      >
        <Box p={{ md: 6 }} flexBasis={{ base: '100%', md: '75%' }}>
          <Heading as="h1" size="3xl" fontWeight="light" mb={4} mt={8}>
            HealthCare Management Consulting
          </Heading>
          <Heading as="h5" size="lg" color="brand.one" fontWeight="extrabold">
            Over 35 years of proven industry knowledge&nbsp;and&nbsp;expertise
          </Heading>
        </Box>
        <Box flexBasis={{ base: '100%', md: '30%' }}>
          <Box position="relative" my={16}>
            <Box
              bg="brand.one"
              h="200px"
              w="350px"
              pos="absolute"
              top="-40px"
              right="-40px"
              p={3}
            />
            <Img fixed={data.consultImage.childImageSharp.fixed} />
          </Box>
        </Box>
      </Flex>
    </Container>
  </Box>
);

const SectionOne = ({ data }) => (
  <Box bg="brand.dark" color="white" py={10}>
    <Container maxW="container.xl">
      <Flex
        flexWrap="wrap"
        p={{ md: 6 }}
        my={8}
        minH="400px"
        direction={{ sm: 'column', lg: 'row' }}
        justifyContent={{ sm: 'center', lg: 'space-between' }}
        alignItems={{ sm: 'center', lg: 'flex-start' }}
      >
        <Box width={{ sm: '100%', md: '75%', lg: '50%' }} my={{ base: 10 }}>
          <ScrollAnimation>
            <Heading as="h5" fontWeight="light" lineHeight="base" mb={4}>
              We deliver solutions for health systems, hospitals and medical
              groups backed by 35 years of expertise and battle proven by
              hundreds of clients
            </Heading>
          </ScrollAnimation>
        </Box>

        <Box position="relative" my={{ base: 10 }}>
          <ScrollAnimation>
            <Box
              bg="brand.one"
              h="200px"
              w="350px"
              pos="absolute"
              top="-40px"
              right="-40px"
              p={3}
            />
            <Img fixed={data.clientImage.childImageSharp.fixed} />
          </ScrollAnimation>
        </Box>
      </Flex>
      <Flex
        minH="400px"
        flexWrap="wrap"
        p={{ md: 6 }}
        my={8}
        direction={{ sm: 'column', lg: 'row-reverse' }}
        justifyContent={{ sm: 'center', lg: 'space-between' }}
        alignItems={{ sm: 'center', lg: 'flex-start' }}
      >
        <Box width={{ sm: '100%', md: '75%', lg: '50%' }}>
          <ScrollAnimation>
            <Heading as="h5" fontWeight="light" mb={4} lineHeight="base">
              We specialize in health plan negotiations and financial modeling
              and analysis
            </Heading>
          </ScrollAnimation>
        </Box>

        <Box position="relative" my={10}>
          <ScrollAnimation>
            <Box
              bg="brand.one"
              h="200px"
              w="350px"
              pos="absolute"
              top="-40px"
              left="-40px"
              p={3}
            />
            <Img fixed={data.docImage.childImageSharp.fixed} />
          </ScrollAnimation>
        </Box>
      </Flex>
    </Container>
  </Box>
);

const MeetOurTeam = ({ data }) => (
  <Box bg="brand.three" py={10}>
    <Container maxW="container.xl">
      <Heading as="h3" fontWeight="light" size="2xl" mb={10}>
        Our Professionals
      </Heading>
      <Flex
        flexWrap="wrap"
        p={{ md: 6 }}
        my={8}
        minH="400px"
        direction={{ sm: 'column', lg: 'row' }}
        justifyContent={{ sm: 'center', lg: 'space-between' }}
        alignItems={{ sm: 'center', lg: 'flex-start' }}
      >
        <Box width={{ sm: '100%', md: '75%', lg: '50%' }}>
          <ScrollAnimation>
            <>
              <Heading as="h5" fontWeight="light" mb={4} lineHeight="base">
                Our team of seasoned professionals enable your business
                objectives with confidence and quality.
              </Heading>
              <Heading size="md" mb={10}>
                <LinkOne to="/professionals">Meet our team</LinkOne>
              </Heading>
            </>
          </ScrollAnimation>
        </Box>

        <Box position="relative" my={10}>
          <ScrollAnimation>
            <Box
              bg="brand.two"
              h="200px"
              w="350px"
              pos="absolute"
              top="-40px"
              left="-40px"
              p={3}
            />
            <Img fixed={data.kellyImage.childImageSharp.fixed} />
            <Text>President | Kelly A. Droshin </Text>
          </ScrollAnimation>
        </Box>
      </Flex>
    </Container>
  </Box>
);

const OurClientsInclude = ({ data }) => (
  <Box bg="brand.one" color="" py={10}>
    <Container maxW="container.xl">
      <Heading as="h3" fontWeight="light" size="2xl" mb={10}>
        Our Clients Include
      </Heading>
      <Flex
        justifyContent={{ base: 'center', md: 'space-between' }}
        alignContent="center"
        flexWrap="wrap"
      >
        {data.clientLogos.edges.map((logo, i) => (
          <Box key={makeId()} colSpan={{ base: '4', sm: '2', md: '1' }} p={10}>
            <ScrollAnimation>
              <Img key={logo.id} fixed={logo.node.childImageSharp.fixed} />
            </ScrollAnimation>
          </Box>
        ))}
      </Flex>
    </Container>
  </Box>
);

const OurServices = ({ data }) => (
  <Box bg="brand.three" py={10}>
    <Container maxW="container.xl">
      <Heading as="h3" fontWeight="light" size="2xl" mb={20}>
        Our Services
      </Heading>
      <Grid
        templateColumns="repeat(2, 1fr)"
        templateRows="repeat(2, 1fr)"
        gap={6}
      >
        {services.map((s, i) => (
          <GridItem colSpan={{ base: '2', md: '1' }} key={makeId()} px={6}>
            <ScrollAnimation>
              <Heading size="xl" fontWeight="light" mb={6}>
                {s.name}
              </Heading>
              <UnorderedList>
                {s.services.slice(0, 3).map((ser) => (
                  <ListItem key={makeId()}>
                    <Text fontWeight="light" size="xl">
                      {ser}
                    </Text>
                  </ListItem>
                ))}
              </UnorderedList>
              <LinkOne mt={6} to="/services">
                See Full List of Services
              </LinkOne>
            </ScrollAnimation>
          </GridItem>
        ))}
      </Grid>
    </Container>
  </Box>
);

const WhatPeopleAreSaying = ({ data }) => (
  <Box bg="brand.dark" color="white" py={10}>
    <Container maxW="container.xl" my={24} minH="400px">
      <Heading as="h3" fontWeight="light" size="2xl" mb={20}>
        Testimonials
      </Heading>
      <Container maxW="container.md" centerContent="true">
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          {data.testimonials.edges.slice(0, 3).map((test, i) => (
            <Box
              key={test.node.id}
              px={10}
              my={10}
              key={makeId()}
              flexBasis={{ base: '100%', md: '50%' }}
              flexGrow="1"
              position="relative"
            >
              <ScrollAnimation>
                <Box top="-20px" left="-20px" position="absolute" opacity="0.2">
                  <SmartQuote width="100px" height="auto" fill="white" />
                </Box>
                <Text color="white" fontSize="2xl" lineHeight="base">
                  {test.node.frontmatter.testimonial}
                </Text>
              </ScrollAnimation>
            </Box>
          ))}
        </Flex>
      </Container>
    </Container>
  </Box>
);

const indexPage = ({ data }) => {
  return (
    <Layout>
      <Hero data={data} />
      <SectionOne data={data} />
      <MeetOurTeam data={data} />
      <OurClientsInclude data={data} />
      <OurServices data={data} />
      <WhatPeopleAreSaying data={data} />
    </Layout>
  );
};

export const query = graphql`
  query {
    profitImage: file(relativePath: { eq: "profit_ill.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    testimonials: allMarkdownRemark(
      filter: { fields: { path: { regex: "/testimonials/" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            testimonial
          }
        }
      }
    }

    kellyImage: file(relativePath: { eq: "kelly-rredit-1-003-.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 350) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    clientImage: file(relativePath: { eq: "pix_client.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    docImage: file(relativePath: { eq: "doc-crossed-arms.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    consultImage: file(relativePath: { eq: "home_pix.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    clientLogos: allFile(
      filter: { relativeDirectory: { eq: "clients" }, extension: { eq: "png" } }
    ) {
      edges {
        node {
          id
          extension
          childImageSharp {
            id
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;

export default indexPage;
